import { Button, Card, Form, Input, Row } from 'antd';
import React, { useState } from 'react';

export default function (props) {
  // eslint-disable-next-line no-unused-vars
  const { deleteTask, closeModal } = props;
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    try {
      deleteTask(values).then(() => {
        setLoading(false);
        form.resetFields();
      });
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <div>
      <Card
        padding="30px 20"
        title={
          <Row justify="space-between" align="middle">
            Reason for Rejection
          </Row>
        }
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'reason is required '
              }
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Save
          </Button>
        </Form>
      </Card>
    </div>
  );
}
