import { useMutation } from '@apollo/client';
import { Button, Divider, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { Email } from '../../assets/svg';
import { BREAKPOINTS } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { RECOVER_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ResetPassword = () => {
  const [emailValue, setEmailValue] = useState();
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [resetPassword] = useMutation(RECOVER_PASSWORD);
  const [loading, setLoading] = useState(false);

  const onFinish = (formValues) => {
    setLoading(true);
    return resetPassword({
      variables: { email: formValues?.email || emailValue }
    })
      .then(() => {
        setEmailValue(formValues?.email || emailValue);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.message);
        setLoading(false);
      });
  };

  return (
    <div style={{ height: '100%' }} className="d-flex">
      {isMobileViewPort && (
        <div className="reset">
          <img src={logo} alt="logo" className="logo" height="120px" />
          <div className="reset-text">
            <h1>Making Job equity for Blacks a reality</h1>
            <p className="mt-25 font-16">
              A new tech-engineered platform that leverages A.I. & M.L. to make
              it easy for companies to find and hire Black talents, onboard and
              manage their experiences.
            </p>
          </div>
        </div>
      )}
      {emailValue ? (
        <div>
          <div className="reset-screen">
            <h1>Check Your Email</h1>
            <p>
              We have sent a password recover instructions to your email, please
              check your Promotions or spam folder as well.
            </p>
            <div className="login-form">
              <Form layout="vertical">
                <Form.Item>
                  <Button
                    type="primary"
                    className="fill-width"
                    onClick={onFinish}
                    loading={loading}
                  >
                    Resend
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {!isMobileViewPort && (
            <div className="mobile-login-header">
              <div className="d-flex align-center justify-between">
                <img
                  src={mobileLogo}
                  alt="logo"
                  className="logo"
                  height="35px"
                />
              </div>
              <Divider />
            </div>
          )}
          <div className="reset-screen">
            <h1>Reset Password</h1>
            <p>
              Enter the email address associated with your account and we'll
              send an email with instructions to reset your password
            </p>
            <div className="reset-form">
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item label="Email" name="email" rules={[required, email]}>
                  <Input placeholder="Enter your Email" prefix={<Email />} />
                </Form.Item>
                <Button
                  type="primary"
                  className="fill-width"
                  htmlType="submit"
                  loading={loading}
                >
                  Send
                </Button>
                <p className="mt-25">
                  By clicking “Send” you're agreeing that the above information
                  is true
                </p>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
