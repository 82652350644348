import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Popover, Row, Select, Spin, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import AddUserModal from '../../users/AddUserModal';
import { UPDATE_COMPANY_VERIFICATION } from '../graphql/Mutations';
import { GET_COMPANY_LIST } from '../graphql/Queries';
import ReasonDeletion from './ReasonDeletion';

const { Option } = Select;

const CompanyList = () => {
  const initialUserFilter = {
    skip: 0,
    limit: 10
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  // eslint-disable-next-line no-unused-vars
  const [statusFilter, setStatusFilter] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState();

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [sortedInfo, setSortedInfo] = useState({});
  const [search, setSearch] = useState('');

  const [userFilter, setUserFilter] = useState(initialUserFilter);

  // eslint-disable-next-line no-unused-vars

  const [listCompaniesDetails, { loading, data, refetch }] = useLazyQuery(
    GET_COMPANY_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.listCompaniesDetails?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  const [
    updateCompanyVerification,
    { loading: updateCompanyVerificationLoading }
  ] = useMutation(UPDATE_COMPANY_VERIFICATION, {
    onCompleted() {
      setUserFilter({ ...userFilter, skip: 0, search: search });
      listCompaniesDetails({
        variables: {
          filters: {
            ...userFilter,
            skip: 0,
            search: search,
            status: statusFilter
          }
        }
      });
    },
    onError(e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  });

  useEffect(() => {
    listCompaniesDetails({ variables: { filters: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserFilter({ ...userFilter, skip: 0, search: search });
    listCompaniesDetails({
      variables: {
        filters: {
          ...userFilter,
          skip: 0,
          search: search,
          status: statusFilter
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setUserFilter({
      skip: 0,
      limit: 10
    });
    setPaginationProp(initialPaginationValue);
    listCompaniesDetails({ variables: { filters: { ...initialUserFilter } } });
  };

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setUserFilter({ ...userFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC'
      });
      listCompaniesDetails({
        variables: {
          filters: {
            ...userFilter,
            skip,
            limit: pagination.pageSize
          }
        }
      });
    } else {
      setUserFilter({
        ...userFilter
      });
      listCompaniesDetails({
        variables: {
          filters: {
            ...userFilter,
            skip,
            limit: pagination.pageSize
          }
        }
      });
    }
  };
  const handleClick = (record, status, values = '') => {
    // eslint-disable-next-line no-console
    console.log(values);
    setSelectedUserId(record?.id);
    updateCompanyVerification({
      variables: {
        data: {
          status: status,
          reason: values?.reason
        },
        where: { id: record?.id }
      }
    });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (date) => {
        return moment(date).format('LLL');
      }
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => {
        if (status === 'APPROVED') {
          return <Tag color="green">Approved</Tag>;
        }
        if (status === 'REJECTED') {
          return <Tag color="red">Declined</Tag>;
        }
        if (status === 'PENDING') {
          return <Tag color="blue">Pending</Tag>;
        }
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center',
      render: (id, record) => {
        return (
          <>
            {record?.status === 'PENDING' && (
              <Spin
                spinning={
                  record?.id === selectedUserId &&
                  updateCompanyVerificationLoading
                }
              >
                <Button
                  loading={loading}
                  type="primary"
                  shape="round"
                  className="btn-action"
                  onClick={() => handleClick(record, 'APPROVED')}
                >
                  Accept
                </Button>
                <Popover
                  trigger="click"
                  placement="left"
                  content={
                    <ReasonDeletion
                      deleteTask={(values) =>
                        handleClick(record, 'REJECTED', values)
                      }
                    />
                  }
                >
                  <Button
                    loading={loading}
                    shape="round"
                    className="btn-action"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Reject
                  </Button>
                </Popover>
              </Spin>
            )}
          </>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setSearch(value);
    setUserFilter({ ...userFilter, skip: 0, search: value });
    listCompaniesDetails({
      variables: {
        filters: { ...userFilter, skip: 0, search: value, status: statusFilter }
      }
    });
  };

  return (
    <div className="company-list">
      {showModal && (
        <AddUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
          refetchUserDataWithInitialValues={refetchUserDetails}
          refetchUserData={refetch}
        />
      )}
      <Portal portalId="user-addEdit-btn">
        <Button type="primary" onClick={handleAddEditUser}>
          Add User
        </Button>
      </Portal>
      <Row className="mb-10">
        <Col>
          <SearchComponent
            id="search-container-id"
            name="Company"
            getData={onSearchChange}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Status"
            style={{ width: 150 }}
            onChange={setStatusFilter}
          >
            <Option>All</Option>
            <Option value="APPROVED">Approved</Option>
            <Option value="PENDING">Pending</Option>
            <Option value="REJECTED">Rejected</Option>
          </Select>
        </Col>
      </Row>
      <CommonTable
        loadingData={loading}
        columns={columns}
        data={data?.listCompaniesDetails?.data}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
      />
    </div>
  );
};

export default CompanyList;
