import { Button, Divider, Form, Input } from 'antd';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import logo from '../../assets/logo.png';
import mobileLogo from '../../assets/mobileLogo.png';
import { PasswordIcon } from '../../assets/svg';
import { BREAKPOINTS, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { confirmPasswordResetCustom } from './Firebase';

const { required, password } = formValidatorRules;

const ChangePassword = () => {
  const history = useHistory();
  const { search } = useLocation();
  const isMobileViewPort = useMedia(`(min-width: ${BREAKPOINTS?.mobile}px)`);
  const [loading, setLoading] = useState(false);
  const query = queryString.parse(search);
  const { oobCode, type } = query;

  const onFinish = async (formValues) => {
    const newPassword = formValues.password;
    await confirmPasswordResetCustom(oobCode, newPassword, setLoading);
    if (type === 'COMPANY') {
      history.replace(ROUTES.COMPANY_LOGIN);
    } else {
      history.replace(ROUTES.LOGIN);
    }
  };

  return (
    <div style={{ height: '100%' }} className="d-flex">
      {isMobileViewPort && (
        <div className="reset">
          <img src={logo} alt="logo" className="logo" height="120px" />
          <div className="reset-text">
            <h1>Making Job equity for Blacks a reality</h1>
            <p className="mt-25 font-16">
              A new tech-engineered platform that leverages A.I. & M.L. to make
              it easy for companies to find and hire Black talents, onboard and
              manage their experiences.
            </p>
          </div>
        </div>
      )}
      <div>
        {!isMobileViewPort && (
          <div className="mobile-login-header">
            <div className="d-flex align-center justify-between">
              <img src={mobileLogo} alt="logo" className="logo" height="35px" />
            </div>
            <Divider />
          </div>
        )}
        <div className="reset-screen">
          <h1>Create new password</h1>
          <div className="reset-form">
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Password"
                name="password"
                rules={[required, password]}
              >
                <Input.Password
                  placeholder="Enter your Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm-password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(new Error('Required'));
                      }
                      if (value !== getFieldValue('password')) {
                        return Promise.reject(
                          new Error('Passwords do not match')
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  placeholder="Enter your Confirm Password"
                  prefix={<PasswordIcon />}
                />
              </Form.Item>
              <Button
                type="primary"
                className="fill-width"
                htmlType="submit"
                loading={loading}
              >
                Send
              </Button>
              <p className="mt-25">
                By clicking “Send” you're agreeing that the above information is
                accurate
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
