import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_COMPANY_VERIFICATION = gql`
  mutation updateCompanyVerification(
    $data: UpdateCompanyVerificationInput
    $where: CommonWhereInput!
  ) {
    updateCompanyVerification(data: $data, where: $where) {
      message
    }
  }
`;
