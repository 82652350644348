import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './company.less';
import CompanyList from './components/CompanyList';

const CompanyWrapper = () => {
  return (
    <div className="company">
      <Switch>
        <Route exact path={ROUTES.COMPANY} component={CompanyList} />
      </Switch>
    </div>
  );
};

export default CompanyWrapper;
