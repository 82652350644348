import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_COMPANY_LIST = gql`
  query listCompaniesDetails($filters: ListCompaniesDetailsInput!) {
    listCompaniesDetails(filters: $filters) {
      count
      data {
        id
        name
        email
        status
        createdAt
      }
    }
  }
`;
